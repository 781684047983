import styled from "styled-components";
import img from "./FourPawsDogWash_Pattern_50.png";

export const MiddleContainer = styled("section")`
  position: relative;
  min-height: 100%;

  @media only screen and (max-width: 1024px) {
    padding: 4rem 0 4rem;
  }

  &::before {
    background: center/cover url(${img}) no-repeat;
    content: "";
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    inset: 0px -18px;
    opacity: 0.15;
  }
`;