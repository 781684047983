import styled from "styled-components";

export const StyledButton = styled("button")<any>`
  background: ${(p) => p.color || "#EA8642"};
  color: ${(p) => (p.color ? "#EA8642" : "#fff")};
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 13px 0;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 180px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);
  @media only screen and (max-width: 658px) {
    display: none;
  }

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: #C7D3DD;
  }
`;
